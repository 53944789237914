import "moment/locale/pt"
import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import "../style/@wordpress/style.css"
import "../style/@wordpress/theme.css"

import Seo from "../components/SEO"
import SectionHero from "../components/SectionHero"
import moment from "moment"
import NewsletterSection from "../components/NewsletterSection"
import ListaArtigos from "../components/ListaArtigos"

const BlogPostTemplate = ({ data: { previous, next, post, related } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }

  moment.locale("pt")
  const postDate = moment(post.date).format("DD MMMM YYYY")

  const heroImage = post.featuredImage ? (
    <GatsbyImage
      image={featuredImage.data}
      alt={post.title || "mmv advogados"}
    />
  ) : (
    <StaticImage
      src="../images/hero-bg.jpg"
      alt="mmv_heroimage"
      imgStyle={{ objectPosition: "0 30%" }}
    />
  )

  let categoriesText = ""
  post.categories.nodes.map(
    cat => (categoriesText += (categoriesText === "" ? "" : ", ") + cat.name)
  ) //TODO: VER MELHOR SOLUCAO

  //const latestBlogPosts = useLatestBlogPosts()
  const latestBlogPosts = related.edges

  return (
    <>
      <Seo title={post.title} description={post.excerpt} />

      <SectionHero
        title={post.title}
        text={postDate + " — " + categoriesText}
        image={heroImage}
        secondTextRight={true}
      />

      <section className="section-artigo">
        <div className="container">
          <div className="corpo-artigo">
            <div itemProp="articleBody">{parse(post.content)}</div>
          </div>

          <div className="artigo-navigation">
            <div className="navigation-item">
              {previous && (
                <div className="navigation-item">
                  <span className="label">Artigo anterior</span>
                  <Link to={"/artigo/" + previous.slug} rel="prev">
                    {parse(previous.title)}
                  </Link>
                </div>
              )}
            </div>

            <div className="navigation-item">
              {next && (
                <div className="navigation-item text-end">
                  <span className="label">Artigo seguinte</span>
                  <Link to={"/artigo/" + next.slug} rel="next">
                    {parse(next.title)}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <NewsletterSection />

      <ListaArtigos
        title="Relacionados"
        showCategories={false}
        isShort={true}
        artigosList={latestBlogPosts}
      />
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "DD MMMM YYYY")
      categories {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
      slug
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
      slug
    }
    related: allWpPost(sort: { fields: date, order: DESC }, limit: 3) {
      edges {
        node {
          id
          title
          slug
          excerpt
          uri
          featuredImage {
            node {
              mediaItemUrl
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: DOMINANT_COLOR
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          date
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`
